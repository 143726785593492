import photo1 from './Wimmer/1.jpg';
import photo2 from './Wimmer/2.jpg';
import photo3 from './Wimmer/3.jpg';
import photo4 from './Wimmer/4.jpg';
import photo5 from './Wimmer/5.jpg';

const title = 'Weingut und Straußenfarm Wimmer in Oggau';
const short_title = 'Weingut Wimmer';
const path = 'Weingut';
const img = photo5;
const videos = {
  lang: 'https://www.youtube.com/watch?v=dX6KWpxvWtk',
  kurz: 'https://www.youtube.com/watch?v=aJLYbo1L79o'
}
const photos = [
  {src: photo1, width: 1, height: .6675},
  {src: photo2, width: 1, height: .6675},
  {src: photo3, width: 1, height: .6675},
  {src: photo4, width: 1, height: .6675},
  {src: photo5, width: 1, height: .6675}
];
const intro = `Die Brüder Thomas und Florian Wimmer führen ein Weingut und eine Bio-Straußenfarm mit Liebe zur Natur. Ihre traditionellen Rebsorten bringen sie durch schonende Bewirtschaftung zum Wohlgeschmack. Auch die Nachfrage am Straußenfleisch in bester Bioqualität wächst.`; 
const content = <>
<p><i>Zwischen traditionellen Rebsorten und exotischem Getier arbeiten die Brüder Thomas und Florian Wimmer. Mit Liebe zur Natur bewirtschaften sie schonend die Böden für wohlschmeckenden Wein und das Ackerland zur Futtergewinnung für die eigene Straußenfarm. Diese hat 2014 als erste in Österreich den Bio-Status erhalten, denn hier brüten die Tiere selbst, haben genügend Auslauf und werden mit den eigens produzierten Futtermitteln gefüttert. Bei Führungen können sich Besucherinnen und Besucher jederzeit selbst davon überzeugen und erhalten Einblick, wie beste Qualität regional entsteht. </i></p>
<p>Seit 2015 sind das Weingut und die Straußenfarm das Markenzeichen der Brüder Wimmer. Während sich Thomas Wimmer verstärkt um das 1843 gegründete Familienweingut kümmert, sind es für Florian Wimmer die Tiere, denen er seine ganze Aufmerksamkeit schenkt. Nach der Hofübernahme seines Onkels hat er auf Bio-Produktion umgestellt, das Erfolgsrezept für Thomas Wimmer und seine Weine lautet: Qualität vor Quantität. Mit diesen Qualitätsansprüchen sind sich die beiden Brüder voll und ganz einig. In allen weiteren Entscheidungen, den Betrieb aufrecht zu erhalten und zukunftsfähig zu gestalten, finden sie immer wieder einen guten Mittelweg. </p>
<h2>Qualität vor Quantität</h2>
<p>In Oggau im Burgenland ist die Sonne zuhause. Das macht die fruchtigen Weißweine, gehaltvollen Rotweine, Obstbrände und Säfte der Wimmers so hervorragend im Geschmack. Dies wissen viele ihrer Kundinnen und Kunden zu schätzen. Als sie die Weinprämierung „Burgenland Gold“ für ihren Blaufränkischen Wein aus dem Jahr 2018 erhalten haben, war der größte Teil bereits verkauft. Thomas Wimmer ist sehr bedacht auf seine Rebstöcke: „Wir haben neun Hektar mit Weinreben, die wir auf unterschiedlichen Lagen in ganz Oggau verteilt haben. Falls an einer Stelle Hagel niedergeht, können wir durch eine andere Lage den Ausfall ersetzen.“ Hagel, trockene, aber auch nasse Sommer sind die Sorgen eines jeden Weinbauers. Dennoch ist der Sommer die schönste Zeit für den Winzer. Er kann den Rebstöcken beim Wachsen zusehen. Spritzmittel werden sparsam und mit Bedacht eingesetzt: „Wir achten beim Spritzen darauf, ob das Wetter das überhaupt verlangt. Wir schieben das lange hinaus; einerseits um es uns zu ersparen und andererseits natürlich der Natur zuliebe.“ Auch bei der Ernte wird schonend vorgegangen und die frischen Temperaturen der Morgenstunde genutzt, um die Trauben schon kühl in den Keller zu bekommen. „Je kühler das Traubenmaterial ist, desto besser ist es für die Verarbeitung und desto mehr Energie wird eingespart. Der Weißwein beispielsweise kommt nach der Ernte direkt in die Presse“, sagt Thomas Wimmer. Als Familienbetrieb ist es ihm umso wichtiger, dass sein Bruder Florian Rückmeldung zum Geschmack des Weines gibt. Auch bei der Versorgung unterstützen sie sich gegenseitig. „Einmal ist im Weingut mehr Arbeit und dann wieder bei der Straußenfarm. Zudem müssen die Tiere täglich betreut werden und da helfe ich aus. Sonst konnte Florian nicht in Urlaub fahren“, so Thomas Wimmer. </p>
<h2>Exoten im Burgenland</h2>
<p>Wenn Florian Wimmer gerade nicht auf Urlaub ist, befindet er sich bei seinen 23 Straußen oder auf den dazugehörigen Ackerflächen von 50 Hektar. Diese dienen der Futterbeschaffung. </p>
<p>Sein Onkel war 1993 der erste Burgenländer mit einer Straußenfarm. „Nach 20 Jahren hat er gesagt; es ist genug und er will in Pension gehen. Da sind wir ins Spiel gekommen und ich habe die Straußenfarm übernommen und auf Bio umgestellt“, so Florian Wimmer. Er achtet auf biologisches Futtermittel, das er selbst produziert. Darunter finden sich Weizen, Hafer, Gerste und täglich frisch gemahlene Luzerne. „Wenn es den Tieren gut geht, dann geht es mir auch gut“, strahlt Florian Wimmer. </p>
<p>Die Strauße können sich das ganze Jahr frei auf der Koppel bewegen und dort auch selbst brüten. Brutkästen verwenden die Wimmers keine. Das Besondere bei den Straußen ist, dass sie ihre Eier nicht einzeln ausbrüten, sondern sammeln. Das wiederum bedeutet: wird ein Ei zurückgelassen, ist es verloren. Ein solches Straußenei kommt auf eine Eirohmasse von ca. 1,5 Kilogramm. Das entspricht in etwa 26 Hühnereiern. </p>
<p>Wenn im Sommer die Temperaturen über 32 Grad steigen, erhalten die Strauße eine kleine Dusche aus dem Wasserschlauch. Laut Florian Wimmer kühlt sie das ab. Denn selbst die wärmeliebenden Exoten aus Afrika spüren die Hitze. Im Winter können die Tiere jederzeit in den Stall gehen. Das Gute am Strauß ist, „dass man so ziemlich alles verwerten kann: ob Leder, Knochen oder Sehnen für die Hunde, Federn als Staubwedel oder Fleisch für den Verzehr. Das Fleisch ist sehr dunkel und gesund. Auf 100 Gramm Straußenfleisch kommen null Gramm Fett und es ist cholesterinfrei“, sagt Florian Wimmer. 2014 ist die Straußenfarm auch als Bio-Farm zertifiziert. Genügend Auslauf für die Tiere ist Wimmer dabei das Wichtigste und so wird die hohe Nachfrage an Fleisch nicht eher gedeckt werden können, bis er weiteren Platz für seine Tiere gefunden hat. </p>
<h2>Ein nachhaltiger und langlebiger Betrieb</h2>
<p>Mit der Verknüpfung des Weinguts und der Straußenfarm haben die Brüder Florian und Thomas Wimmer eine neue Marke und Alleinstellungsmerkmal geschaffen, das sich auch auf den Etiketten der Weine widerspiegelt. Seit 2019 können die Weine verziert mit Straußen- Logo auch im Onlineshop gekauft werden. Die Brüder haben mit der Übernahme des Betriebs ganz bewusst ihr Augenmerk auf den Verkauf gelegt, was für die beiden bei gutem Marketing beginnt. Dank ihrem Pioniergeist werden sie nun auch online schneller gefunden und machen den virtuellen sowie realen Besuch zu einem ganz besonderen Erlebnis.Ob sich der Nachwuchs einmal für den Familienbetrieb interessieren wird, steht noch in den Sternen. Dennoch ist es der größte Wunsch der Brüder Wimmer einen Betrieb aufzustellen, der wirklich nachhaltig sowie langlebig ist und sich selber trägt, so dass man ihn ohne Probleme an seine Nachkommen weitergeben kann.</p>
</>

const weingut = {
  path,
  img,
  photos,
  videos,
  title,
  short_title,
  intro,
  content
}

export default weingut;
