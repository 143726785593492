import photo1 from './Feldalm/1.jpg';
import photo2 from './Feldalm/2.jpg';
import photo3 from './Feldalm/3.jpg';
import photo4 from './Feldalm/4.jpg';
import photo5 from './Feldalm/5.jpg';
import photo6 from './Feldalm/6.jpg';

const title = 'Almkäserei Feldalm';
const short_title = 'Feldalm';
const path = 'Feldalm';
const img = photo5;
const videos = {
  lang: 'https://www.youtube.com/watch?v=m2M5dzk7Jso',
  kurz: 'https://www.youtube.com/watch?v=zfYlUXi0JRY'
}
const photos = [{src: photo1, width: 1, height: 1},{src: photo2, width: 1, height: 0.666},{src: photo3, width: 1, height: 1},{src: photo4, width: 1, height: 1},{src: photo5, width: 1, height: 0.666},{src: photo6, width: 1, height: 0.666}];
const intro = `Familie Jäger betreibt auf 1330 Metern Höhe eine Käserei und unterstützt damit die Landwirtschaft von weiteren sechs ansässigen Bauern. Ihren Bergkäse und andere Erzeugnisse verkaufen sie vor Ort und ab Herbst im Tal.`; 
const content = <>
<h2>Almkäserei Feldalm: Familie Jäger aus Ebbs in Tirol</h2>
<p>Aus purer Leidenschaft betreiben die Eheleute Stefanie und Michael Jäger eine Sennerei auf der Hochalm. Das Käsen auf der Alm, trotz erschwerter Bedingungen, scheuen sie nicht. Die Agrargemeinschaft befürwortete ihr Vorhaben von Anfang. Aus der Milch benachbarter Höfe und dem eigenen Fleckvieh produzieren sie in den Sommermonaten Butter, milden Schnittkäse, Tilsiter und würzigen Bergkäse.</p>
<p>Familie Jäger hat es sich mit ihrer modernen Käserei zum Ziel gesetzt, die Fertigung von Käse fortzuführen, wie es seit Jahrzehnten in der Region Walchsee Tradition war. Mit diesem Schritt sichert sie das Überleben von sechs umliegenden Bauernfamilien. Michael Jäger sah sich in der Pflicht: „Wenn hier oben die Milchverarbeitung stirbt, stirbt auch die Milchförderung.“ Dementsprechend beschloss Familie Jäger 2015 den Betrieb des Vorgängers zu übernehmen und eine moderne Anlage zu bauen. Diese ist seit Herbst 2019 vollständig im Betrieb und verfügt über die aktuellsten technischen Standards und Hygienevorschriften. Für das Projekt erhielt Familie Jäger von Anfang an viel Zuspruch. „Eigentlich möchte jeder, dass es weiter geht und hier Käse produziert wird“, so Stefanie Jäger. Alle Produkte werden direkt vor Ort auf 1330 Metern Höhe hergestellt.</p>

<h2>Investition in eine moderne Almkäserei</h2>
<p>Die Almkäserei Feldalm verarbeitet täglich 1200 Liter Milch. Sie ist ein reines Naturprodukt und die Erzeugnisse daraus bio-zertifiziert. Aus der Rohmilch werden in einem ersten Schritt Rahm und Butter. Am Ende der weiteren Verarbeitung steht die traditionelle Käsesorte des Bergkäses sowie andere Milcherzeugnisse. Ein Prozess von mindestens drei Monaten und ein Käse, der im Erdkeller ruhen darf. Der Weg von der Alm ins Tal verläuft zu Fuß durchs Naturschutzgebiet. Ausschließlich die Bauern dürfen das Gebiet mit dem Auto befahren, um die nötigsten Besorgungen zu transportieren. Unten im Tal wird der Biokäse nach dem Sommer in den eigenen Läden oder am Bauernmarkt verkauft. Darunter auch eine neue Produktentscheidung - dem Schnittkäse mit Schnittlauch oder anderen Kräutern. Dafür erhielt die Sennerei noch gleich im ersten Jahr die Bronzemedaille der Almkäserei in Walldür. Jährlich sind es 3000 kg Käse, die in den Sommermonaten auf der Alm entstehen. Sobald die Rinde reif ist, können die Käselaibe ins Tal überführt werden.</p>

<h2>Das Leben in der Natur</h2>
<p>Bevor der wohlschmeckende Käse an den Mann oder an die Frau gebracht wird, sind es die Kühe, die den ganzen Tag auf der Weide grasen. Im Mai treiben die Bauern ihre Tiere auf die Alm, wo sie bis in den Herbst hinein im Freien sind. Im Hochsommer, wenn die Hochalm in voller Blüte steht, fressen sie dort das satte Gras und die würzigen Kräuter. Diese verleihen dem Käse seine ansehnliche Farbe und den guten Geschmack.<br />
Doch in einem Naturschutzgebiet in hoher Lage ein Gebäude zu errichten, ist eine Herausforderung. Alles wurde genau geplant. Nebst der Kalkulation für das Gebäude und dem Transport aller Materialien, mussten auch Naturschutzverfahren berücksichtigt werden. Selbst ein möglicher Schneeeinfall Anfang September gehörte zur Logistik. Das Wissen über das Etikettieren der Produkte, rechtliche Grundlagen, Versicherungsschutz oder Hygienevorschriften mussten erworben werden. Bis zur Finalisierung der Passion Feldalm brauchte es vier Jahre.
</p>

<h2>Mut, Motivation und Ehrgeiz für die nächste Generation</h2>
<p>Das ein solches Projekt auch zukünftigen Generationen erhalten bleiben soll, erscheint selbstverständlich. „Zur richtigen Zeit, die richtige Entscheidung treffen“, lautet das Geheimrezept von Michael Jäger. Die Landwirtschaft sei im Umbruch und daher sei es wichtig, die nächste Generation von Kindesbeinen an für den Hof zu begeistern und ihn zu übergeben, wenn die Kinder noch jung und motiviert sind. Dieses Familienrezept hat Erfolg. Schließlich stammt Michael Jäger aus dem ältesten Erbhof in Ebbs aus dem Jahr 1361. Michael Jäger, der „Fritzinger Bauer“, ist Nebenerwerbsbauer in 10. Generation. Mit der Investition in ein neues Gebäude ist klar, dass die Sennerei Feldalm in Zukunft auch seine beiden Kinder erfreuen soll.</p>
</>

const feldalm = {
  path,
  img,
  photos,
  videos,
  title,
  short_title,
  intro,
  content
}

export default feldalm;
