import React from 'react';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import styles from './Error404.style';

const useStyles = createUseStyles(styles);

const Error = () => {
  const classes = useStyles();
  return <>
    <main style={{flex:1, display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center'}}>
      <div>Seite nicht gefunden.</div>
      <div style={{maxWidth: '300px', width: '100%', margin: '0 auto', marginTop: '36px'}}>
        <Link className={classes.btn} to={'/'}>Zur Startseite</Link>
      </div>
    </main>
  </>;
};

export default Error;
