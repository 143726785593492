const styles = {
  footer: {
    margin: 0,
    padding: '24px 0',
    color: '#8b8b8b',
    '& div': {
      display: 'flex',
      maxWidth: '900px',
      width: '80%',
      margin: '0 auto'
    },
    '& span': {
      margin: '0 auto 0 0'
    },
    '& a': {
      margin: '0 8px',
      textDecoration: 'none',
      color: '#8b8b8b',
      '&:hover': {
        color: '#000',
      }
    }
  },
  '@media (max-width: 750px)': {
    footer: {
      '& div': {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
      },
      '& span': {
        margin: 0
      }
    }
  }
}

export default styles;
