import React from 'react';
import { createUseStyles } from 'react-jss';
import styles from './Footer.style';

const useStyles = createUseStyles(styles);

const Footer = () => {
  const classes = useStyles();
  const now = new Date();

  return <div className={classes.footer}>
    <div>
      <span><a href="https://meinhof-meinweg.at/" rel="noopener noreferrer">&copy; {now.getFullYear()} Mein Hof - Mein Weg</a></span>
      <a href="https://meinhof-meinweg.at/at/kontakt.php" rel="noopener noreferrer">Kontakt</a>
      <a href="https://meinhof-meinweg.at/at/administration/impressum.php" rel="noopener noreferrer">Impressum</a>
      <a href="https://meinhof-meinweg.at/at/administration/datenschutz.php" rel="noopener noreferrer">Datenschutz</a>
    </div>
  </div>
};

export default Footer;