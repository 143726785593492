import photo1 from './Zacherlgut/1.jpg';
import photo2 from './Zacherlgut/2.jpg';
import photo3 from './Zacherlgut/3.jpg';
import photo4 from './Zacherlgut/4.jpg';
import photo5 from './Zacherlgut/5.jpg';
import photo6 from './Zacherlgut/6.jpg';

const title = 'Begegnungshof Zacherlgut in Thalgau';
const short_title = 'Begegnungshof';
const path = 'Begegnungshof';
const img = photo6;
const photos = [
  {src: photo2, width: 1, height: .5625},
  {src: photo1, width: 1, height: .6675},
  {src: photo3, width: 1, height: .5625},
  {src: photo4, width: 1, height: .5625},
  {src: photo5, width: 1, height: .5625},
  {src: photo6, width: 1, height: .5625}
];
const videos = {
  lang: 'https://www.youtube.com/watch?v=xNnZymX5dqA',
  kurz: 'https://www.youtube.com/watch?v=tEENJ2DFYRc'
}
const intro = `Der Begegnunghof Zacherlgut in Thalgau bietet Raum für die ganzheitliche Pflege von Körper und Geist durch das Arbeiten mit Tieren. Mithilfe von Tiertherapie wird Menschen geholfen, die mit Beeinträchtigungen oder Krankheiten leben.`; 

export const content = <>
<h2>Aktivitäten mit Tieren und Natur</h2>
<p>Menschen, die Freude am Umgang mit Tieren haben, kommen am Zacherlgut besonders auf ihre Kosten. 
Als ausgebildete Reitpädagogin bietet Sandra Schroffner Reitunterricht an. Besucher können außerdem auch Zeit mit Meerschweinchen, Hühnern, Schweinen, Katzen, Hunden und Schafen verbringen. „Manche Leute genießen es zum Beispiel sehr, in der Hängematte bei den Schafen zu liegen. Es ist ein nettes Gefühl von den Schafen umgarnt zu werden, die dich im positiven Sinn fast auffressen,“ erzählt John Grubinger. Für die Begegnung mit der Natur wird der gesamte Hof genutzt. Viele Aktivitäten finden im Garten statt. Sandra Schroffner hat sich mit diesem Projekt einen persönlichen Traum erfüllt. Sie ist stolz darauf, dass ihr Zacherlgut mittlerweile für viele Besucher ein Gesundheits- und Wohlfühlort geworden ist. </p>

<h2>Begegnung mit der Natur und Tieren für Menschen jeden Alters</h2>
<p>Der biologisch geführte Hof wird nicht nur von weit gereisten Personen besucht. Auch Nachbarn, vor allem Kinder, nutzen das Angebot des Zacherlguts intensiv. Sandra Schroffner kooperiert auch mit dem örtlichen Zentrum für Inklusiv- und Sonderpädagogik. Schülern wird so im Zuge des Schulsports Kontakt zu Tieren direkt in den Stallungen, dem Gehege, dem Reitplatz oder der Koppel ermöglicht. </p>

<h2>Vom Milchbetrieb zum Begegnungshof</h2>
<p>John Grubinger hat den elterlichen Milchviehbetrieb 2007 aufgelöst und erst 2012 wieder als Begegnungshof ins Leben gerufen. Im Mai 2018 wurde dem Zacherlgut die Green Care- Zertifizierung verliehen. 
Die größte Herausforderung der letzten Jahre war es, mit alten Traditionen zu brechen und den neuen Betrieb in einem Tempo zu gestalten, mit dem sich alle Beteiligten wohlfühlen. Sandra Schroffners Rat lautet „Niemals übers Knie brechen. Mit vorhandenen Ressourcen arbeiten und alle ins Boot holen. Oft braucht es fünf Gespräche mehr, aber es ist ein gesundes Wachstum.“</p>
</>

const begegnungshof = {
  path,
  img,
  photos,
  videos,
  title,
  short_title,
  intro,
  content
}

export default begegnungshof;
