import {
  HOME_PATH,
  HOME_ID_PATH,
  HOME_VIDEO_PATH
} from '../constants/paths';

import Home from 'routes/Home';


const routes = [
  { id: 'home', path: [HOME_PATH, HOME_ID_PATH, HOME_VIDEO_PATH], component: Home },
];

export default routes;
