const card = {
  transition: 'all .2s',
  display: 'flex',
  width: '100%',
  marginBottom: '16px',
  cursor: 'pointer',
  borderRadius: '10px',
  overflow: 'hidden',
  minHeight: '200px',
  '&:hover': {
    boxShadow: '0 4px 10px rgb(0 0 0 / 8%)',
    transform: 'scale(1.05)',
    '& $cardContent': {
      '& div': {
        backgroundColor: 'rgba(0,0,0,.2)'
      }
    },
    '& $cardHeader': {
      '& div': {
        backgroundColor: 'rgba(0,0,0,.5)'
      },
      '& img': {
        opacity: 1
      }
    }
  }

}
const styles = {
  header: {
    overflow: 'hidden',
    position: 'relative',
    maxHeight: '630px'
  },
  videoScreen: {
    opacity: 0,
    zIndex: 20,
    position: 'absolute',
    top: 0,
    left: '50%',
    margin: '0 0 0 -490px',
    width: '980px',
    height: '554px',
    animation: '$fadeIn 1s',
    animationFillMode: 'forwards',
    animationDelay: '.6s'
  },
  videoWrapper: {
    width: '100%',
    paddingTop: '56.26%',
    position: 'absolute',
    top: 0
  },
  videoPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1
  },
  playerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 0
  },
  playerBtn: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100px',
    zIndex: 1
  },
  hof: {
    position: 'relative',
  },
  background: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%'
  },
  main: {
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    position: 'relative',
    top: 0,
    width: '100%',
    zIndex: 21,
    flex: 1,
    padding: 0
  },
  inner: {
    width: '80%',
    maxWidth: '980px',
    margin: '0 auto',
    padding: '16px 0',
    minHeight: '800px'
  },
  gallery: {
    margin: '24px 0'
  },
  landscape: {
    position: "absolute",
    width: "100%",
    paddingTop: '47.5%',
    zIndex: 0,
    '& img': {
      width: '100%'
    }
  },
  landscapePath: {
    position: "absolute",
    top: 0,
    left: 0,
    marginTop: '-6.1%',
    zIndex: 1,
    width: '100%'
  },
  logoWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 11,
    width: '100%'
  },
  logo: {
    width: '200px',
    margin: '0 auto',
    height: '65px',
    flexDirection: 'center',
    '& img': {
      margin: '0 auto',
      width: '200px'
    } 
  },
  content: {
    width: '100%',
    borderRadius: '10px',
    fontSize: '120%',
    '& h1': {
      padding: '4px 0',
      overflow: 'hidden'
    }
  },
  lnk: {
    opacity: 0,
    width: '100%',
    textDecoration: 'none',
    animation: '$fadeIn .2s',
    animationFillMode: 'forwards',
    transition: 'all .2s',
  },
  playItem: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '80px',
    height: '80px',
    margin: '-40px 0 0 -40px'
  },
  card1: {
    ...card,
    flexDirection: 'row'
  },
  card2: {
    ...card,
    flexDirection: 'row-reverse'
  },
  cardHeader: {
    position: 'relative',
    width: '40%',
    minHeight: '200px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    '& div': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0)'
    },
    '& img': {
      opacity: 0
    }
  },
  cardContent: {
    position: 'relative',
    textDecoration: 'none',
    width: '60%',
    '& h2,p': {
      zIndex: 10,
      margin: 0,
      padding: '16px'
    },
    '& h2': {
      textTransform: 'uppercase'
    },
    '& div': {
      position: 'absolute',
      top: 0,
      zIndex: -1,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0)'
    }
  },
  cardContentText: {
    display: 'block'
  },
  img: {
    width: '100%',
    '& img': {
      width: '100%'
    }
  },
  hl: {
    backgroundColor: '#699761',
    color: '#fff',
    position: 'absolute',
    bottom: 0,
    right: 0,
    fontSize: '100%',
    padding: '8px',
    margin: 0
  },
  playVidBtn: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 5,
    width: '140px',
    margin: '-60px 0 0 -70px',
    top: '50%',
    left: '50%'
  },
  scrollDown: {
    margin: '16px auto 0',
    width: '60px',
    height: '60px',
    padding: 0,
    borderRadius: '50%',
    position: 'relative',
    animation: '$down 1.5s infinite',
    cursor: 'pointer',
    '& img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    '&:hover': {
      '& img': {
        transform: 'scale(1.1)'
      }
    }
  },
  playVideo: {
    display: 'block',
    position: 'relative',
    width: '140px',
    height: '140px',
    borderRadius: '10px',
    backgroundColor: 'rgba(0,0,0,.6)',
    animation: '$fadeIn .2s',
    animationFillMode: 'forwards',
    transition: 'all .2s',
    '& span': {
      color: '#fff',
      fontWeight: '800',
      position: 'absolute',
      width: '100%',
      bottom: '8px',
      left: '0',
      textAlign: 'center'
    },
    '& img': {
      position: 'absolute',
      width: '60px',
      height: '60px',
      top: '50%',
      left: '50%',
      margin: '-30px 0 0 -30px'
    },
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,.9)',
      '& img': {
        transform: 'scale(1.2)'
      }
    },
  },
  breadcrumbs: {
    width: '100%',
    margin: '8 auto 8px',
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '100%',
    opacity: 0,
    animation: '$fadeIn 1s',
    animationFillMode: 'forwards',
    '& div': {
      display: 'flex'
    },
    '& a, span': {
      height: '40px',
      textDecoration: 'none',
      padding: '4px 4px',
      margin: 0,
      borderRadius: '4px',
      color: '#fff',
      backgroundColor: 'rgba(105,151,97,.9)',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    '& a:hover, span:hover': {
      backgroundColor: 'rgba(132,191, 122,.9)'
    },
    '& span': {
      color: '#fff',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    '& b': {
      height: '48px',
      padding: '0 6px',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      color: 'rgba(105,151,97,.9)'
    }
  },
  action: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    marginLeft: 'auto'
  },
  back: {
    opacity: 0,
    margin: '16px auto',
    borderRadius: '4px',
    padding: '8px 16px',
    textDecoration: 'none',
    fontWeight: 700,
    backgroundColor: 'rgba(105,151,97,.9)',
    animation: '$fadeIn .2s',
    animationFillMode: 'forwards',
    transition: 'all .2s',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'rgba(105,151,97,.7)'
    }
  },
  '@media (max-width: 750px)': {
    landscapePath: {
      marginTop: '-5.5%',
    },
    content: {
      fontSize: '110%',
      '& h1': {
        fontSize: '130%',
      },
      '& h2': {
        fontSize: '110%',
      }
    },
    logoWrapper: {
      position: 'relative'
    },
    card1: {
      flexDirection: 'column'
    },
    card2: {
      flexDirection: 'column'
    },
    cardContentText: {
      display: 'none'
    },
    cardHeader: {
      width: '100%',
      minHeight: '300px',
    },
    cardContent: {
      width: '100%'
    },
    playVidBtn: {
      width: '80px',
      margin: '-65px 0 0 -40px',
      top: '50%',
      left: '50%'
    },
    infoBox: {
      padding: 0,
      fontSize: '80%',
      '& img': {
        display: 'none'
      }
    },
    scrollDown: {
      display: 'none'
    },
    playVideo: {
      width: '80px',
      height: '80px',
      '& span': {
        display: 'none'
      }
    },
    breadcrumbs: {
      fontSize: '80%'
    },
  },
  '@media (max-width: 200px)': {
    logo: {
      left: 0,
      maxWidth: '100%'
    }
  },
  '@media (max-width: 980px)': {
    videoScreen: {
      width: '100%',
      paddingTop: '56%',
      top: 0,
      left: 0,
      margin: '0 0 40px 0',
    },
  },
  '@keyframes down': {
    '0%': { transform: 'translate(0)' },
    '20%': { transform: 'translateY(15px)' },
    '40%': { transform: 'translate(0)' }
  },
  '@keyframes fadeIn': {
    '0%': { opacity: 0, transform: 'scale(.5)' },
    '100%': { opacity: 1, transform: 'scale(1)' },
  }
}

export default styles;
