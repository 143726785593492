import photo1 from './Gemuesefreude/1.jpg';
import photo2 from './Gemuesefreude/2.jpg';
import photo3 from './Gemuesefreude/3.jpg';
import photo4 from './Gemuesefreude/4.jpg';

const title = 'Die „gemüsefreude“ aus Sattledt';
const short_title = '„Gemüsefreude“';
const path = 'Gemuesefreude';
const videos = {
  lang: 'https://www.youtube.com/watch?v=R1h7k_P1Cng',
  kurz: 'https://www.youtube.com/watch?v=njnCvyTYlz8'
}
const img = photo2;
const photos = [
  {src: photo2, width: 1, height: .5663},
  {src: photo3, width: 1, height: .5663},
  {src: photo1, width: 1, height: .6675},
  {src: photo4, width: 1, height: .5663}
];

const intro = `Die junge Familie Martin-Mayr aus Sattledt geht beim Gemüseanbau und beim Vertrieb neue Wege. Der Bio-Bauernhof funktioniert nach dem Prinzip der CSA (Community Supported Agriculture) und setzt auf Regionalität, Saisonalität, Vielfältigkeit und Fairness.`; 
const content = <>
<p><i>Die junge Familie Martin-Mayr aus Sattledt geht beim Gemüseanbau und beim Vertrieb neue Wege. Der Bio-Bauernhof funktioniert nach dem Prinzip der CSA (Community Supported Agriculture) und setzt auf Regionalität, Saisonalität, Vielfältigkeit und Fairness. </i></p>
<p>Seit acht Jahren lebt Magdalena Martin-Mayr mit ihrem Mann Jonathan auf dem mehrere Hundert Jahre alten Hof ihrer Großeltern in Sattledt. Die beiden lernten sich während ihres Landwirtschaftsstudiums kennen und bewirtschaften den Bauernhof nun im Alleingang. Ihr Schwerpunkt ist der Gemüseanbau. <br />Innerhalb von drei Jahren Jahre hat das Ehepaar es geschafft, den Hof „gemüsefreude“ als Vollerwerbsbetrieb zu führen. Zuvor wurden die Flächen zehn Jahre lang verpachtet. </p>

<h2>Erntefrisches Obst und Gemüse aus der Region</h2>
<p>Wie es in der Gegend üblich ist, war der Hof früher ein Schweinezucht-Betrieb. Magdalena und Jonathan Martin-Mayr haben beschlossen einen anderen Weg einzuschlagen. Am Gemüseanbau schätzen sie die händische Arbeit und die vielfältigen Möglichkeiten. Auf etwa 1.500 m2 Fläche werden über 200 verschiedene Sorten Biogemüse, -kräuter und -obst angebaut, und vorzugsweise manuell bearbeitet.</p>

<h2>CSA- vermarktender Betrieb </h2>
<p>Das Gemüse wird geerntet und nach einem CSA-Modell (Community Supported Agriculture) vermarktet.  Bei dieser speziellen Form der Direktvermarktung versorgt der Bauernhof eine Saison lang eine fixe Anzahl an Konsumenten mit ihren Produkten. Kunden der „gemüsefreude“ erhalten von April bis Dezember/Jänner wöchentlich eine Gemüsekiste, die jeden Donnerstag in Sattledt, Wels, Kremsmünster oder Wartberg/Krems abgeholt werden muss. <br />Als Gegenleistung übernehmen die Konsumenten die gesamten Kosten und das Risiko der Gemüseproduktion. In Japan gibt es dieses Modell schon seit den 70er Jahren, in den USA seit einigen Jahrzehnten. In Europa wurde das Konzept erstmalig in Frankreich umgesetzt. In Österreich waren die „gemüsefreude“ vor rund 10 Jahren der zweite Hof dieser Art. Heute sind es in etwa 30 Betriebe. Ziel ist es, nur so viel zu produzieren, wie tatsächlich gebraucht wird. Die Kunden schätzen die Qualität der Produkte genauso wie das Bewusstsein, den natürlichen Kreislauf des Anbaus zu erleben. Die Menge des Gemüses im Kistl zeigt auf, ob die Ernte gut war oder nicht. </p>

<h2>Durch langsames Wachstum zum Erfolg</h2>
<p>Begonnen hat Familie Martin-Mayr in einem kleinen Rahmen als Selbstversorger. Später belieferten sie fünf Familien. Nun sind es etwa 80 Haushalte, die wöchentlich mit Gemüse versorgt werden. Dank der CSA- Methode ist der Hof das ganze Jahr über finanziell abgesichert und muss keine Marktpreisschwankungen auf sich nehmen. <br />Magdalena und Jonathan haben sich mit der „gemüsefreude“ einen Lebenstraum erfüllt und freuen sich, dass sich ihr Betrieb in den vergangenen Jahren so positiv entwickeln konnte. </p>
</>

const gemuesefreunde = {
  path,
  img,
  photos,
  videos,
  title,
  short_title,
  intro,
  content
}

export default gemuesefreunde;
