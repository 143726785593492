import photo1 from './Seppbauer/1.jpg';
import photo2 from './Seppbauer/2.jpg';
import photo3 from './Seppbauer/3.jpg';
import photo4 from './Seppbauer/4.jpg';
import photo5 from './Seppbauer/5.jpg';

const title = '„Seppbauer“ Milchbetrieb in Wettmannstätten';
const short_title = '„Seppbauer“';
const path = 'Seppbauer';
const img = photo2;
const videos = {
  lang: 'https://www.youtube.com/watch?v=jzq34LsK2jQ',
  kurz: 'https://www.youtube.com/watch?v=ZeLHiZTKFTI'
}
const photos = [{src: photo1, width: 1, height: .5625},{src: photo2, width: 1, height: 0.5625},{src: photo3, width: 1, height: .5625},{src: photo4, width: 1, height: .5625},{src: photo5, width: 1, height: 0.5625}];
const intro = `Familie Haring betreibt einen Milchkuhbetrieb in Wettmannstätten. Besucher sind am Erlebnishof gerne gesehen: Kinder erhalten einen Einblick in die Arbeit am Bauernhof. Für Erwachsene werden hauseigene Produkte wie Käse und Kürbiskernöl angeboten. `; 
const content = <>
<p>Peter Haring lebt mit seiner Familie am „Seppbauer“ Hof in Wettmannstätten am Fuße des Sausaler Wein- und Hügellandes in der Südsteiermark. Gemeinsam mit seiner Frau Maria hat er 2007 die Landwirtschaft seiner Eltern übernommen und führt den Milchviehbetrieb fort. Er investierte in die Modernisierung des Hofs und produziert heute mit 85 Kühen rund 1 Million Liter Milch pro Jahr. Drei Generationen leben hier unter einem Dach. Bis heute kann Peter Haring auf die Unterstützung seines Vaters zählen, der mit Freude am Hof mitarbeitet. Als Ausgleich zum stressigen Alltag verbringt die Familie ihre freien Stunden mit den Pferden.</p>
<h2>Moderner Milchkuhbetrieb mit Tradition </h2>
<p>In den 70er Jahren gab es in der Gegend noch 42 Milchlieferanten, heute sind es nur noch drei. Als Betrieb ist man von den Milchpreisen abhängig. Viele Kollegen haben im Laufe der Zeit aufgegeben. Als vor drei Jahren die Milchpreise besonders niedrig waren, hat auch Peter Haring kurz an seiner Entscheidung gezweifelt <i>„Da fragt man sich schon, ob das wirklich das Richtige ist“</i>, erzählt er rückblickend <i>„doch das Leben stellt uns keine Herausforderungen, die wir nicht meistern können.“</i></p>

<h2>Maximale Effizienz durch moderne Technologie</h2>
<p>Durch den Einsatz moderner Technik werden manuelle Arbeitsschritte durch automatisierte Prozesse ersetzt. Statt vom Bauern händisch mit der Heugabel, schiebt heute ein Roboter das Futter automatisch zu den Kühen. Die Gesundheit der Tiere wird laufend über Sensoren im Vormagensystem und biometrische Messungen überprüft. Peter Haring beobachtet alle Daten elektronisch auf einem Dashboard. <i>„Ist die Kuh gesund, sind auch die Bäuerin und der Bauer glücklich.“</i></p>
<p>Die Maschinen arbeiten rund um die Uhr und verbessern den Produktionsumfang sowie die Einnahmen. Dadurch kann man von der Landwirtschaft weiterhin gut leben.</p>
<p>Peter Haring ist ständig auf der Suche nach Möglichkeiten Abläufe zu optimieren- das ist auch das Geheimnis seines Erfolgs. Er reist regelmäßig ins Ausland, um neue digitale Methoden und Technologien kennenzulernen. Sein Betrieb fungiert als Beta-Hof für technische Neuentwicklungen. Ein Leben ohne technische Hilfsmittel kann sich der Landwirt kaum noch vorstellen.</p>

<h2>Der Ursprung der Lebensmittel</h2>
<p>Maria Haring-Weigl öffnet ihre Tür sehr gerne für Gäste. Ihr Hof und die Milchproduktion sollen für alle transparent sein. Sie bietet „Schule am Bauernhof“-Angebote für Kinder und Käseverkostungen für Erwachsene an. Nebenher verkauft Familie Haring auch Steirisches Kürbiskernöl, das dank der sonnenreichen Tage im Süden der Steiermark besonders gehaltvoll ist. <i>„Es ist einfach schön zu erleben, wie die Kinder mit den Tieren am Bauernhof umgehen und Umweltbewusstsein entwickeln"</i> sagt Maria, deren Anliegen es ist, Konsumenten den Ursprung unserer Lebensmittel wieder näher zu bringen. </p>

</>

const seppbauer = {
  path,
  img,
  photos,
  videos,
  title,
  short_title,
  intro,
  content
}

export default seppbauer;
