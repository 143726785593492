import photo1 from './Krappfelder/1.jpg';
import photo2 from './Krappfelder/2.jpg';
import photo3 from './Krappfelder/3.jpg';
import photo4 from './Krappfelder/4.jpg';
import photo5 from './Krappfelder/5.jpg';

const title = 'Krappfelder Bio-Bauernhof: Speiseeis aus Ziegenmilch';
const short_title = 'Krappfelder Eis';
const path = 'Krappfelder';
const img = photo5;
const videos = {
  lang: 'https://www.youtube.com/watch?v=UkACBzaaeao',
  kurz: 'https://www.youtube.com/watch?v=7vrqwjR939M'
}
const photos = [{src: photo1, width: 1, height: .5625},{src: photo2, width: 1, height: .6675},{src: photo3, width: 1, height: .5625},{src: photo4, width: 1, height: .5625},{src: photo5, width: 1, height: 0.6675}];
const intro = `Seit 2013 erzeugt Familie Pobaschnig auf dem Krappfelder Bio-Bauernhof in der idyllischen Kärntner Hügellandschaft von Zeindorf 100%iges Bio-Ziegeneis nach feinster handwerklicher Tradition.`; 
const content = <>
<h2>Außergewöhnliche Geschmäcker versüßen auf dem Krappfelder Bio-Bauernhof das Leben. Neugierige Naschkatzen kommen mit dem Speiseeis auf Ziegenmilchbasis auf ihre Kosten.</h2>
<p>Seit 2013 erzeugt Familie Pobaschnig auf dem Krappfelder Bio-Bauernhof in der idyllischen Kärntner Hügellandschaft von Zeindorf 100%iges Bio-Ziegeneis nach feinster handwerklicher Tradition. Mit über 50 verschiedenen Sorten bringen sie ganz neue Geschmackserlebnisse in die Welt des Speiseeises.<br />
Die Früchte für die Verfeinerung beziehen sie regional aus Kärnten und der Steiermark, für etwas ungewohnte, durchaus delikate Rezepturen wie das erfrischende Basilikum-Zitronen-Eis kommen die Zutaten direkt aus ihrem Garten. Auch die konventionelle Variante mit Bio-Kuhmilch lässt keine Wünsche übrig.</p>

<h2>Eine Allergie machte erfinderisch - und anfangs wurde alles falsch gemacht, was man falsch machen konnte.</h2>
<p>Bernd und Petra Pobaschnig setzten neue Maßstäbe, als sie den Milchbetrieb für Kuh- und Ziegenmilch mit über 65 Hektar Landnutzfläche (davon 15 ha im eigenen Besitz) und 10 Hektar Forstnutzfläche 2012 übernommen haben: Wegen Bernds Allergie gegen Kuhmilch wurde geplant, Ziegenmilch für KundInnen interessanter zu machen, und so kam es zur Idee, neben der normalen Milchproduktion eine Eismanufaktur ins Leben zu rufen. Damit füllten sie tatsächlich eine Marktlücke und konnten den Fortbestand des Familienbetriebs sichern: Anfangs wurde nur an einem Tag in der Woche produziert, mittlerweile helfen ihnen 3 MitarbeiterInnen bei der ganzwöchigen Produktion, im Sommer wird sogar aufgestockt auf 5 MitarbeiterInnen. Der Weg dorthin war nicht leicht, Petra weiß davon ein Lied zu singen: Ein Kollege hat anfangs für sie eine Probecharge gemacht. <i>"Da haben wir eigentlich alles falsch gemacht was lei sein kann."</i> Aber der Geschmack des Ergebnisses machte Mut und überzeugte auch die Behörden. Damit nicht genug: Überzeugungskunst und unerschütterliche Begeisterung waren Voraussetzung, um den Hof schließlich so weit zu bringen. Dass man erfinderisch sein muss, fordert viel Wagemut, den Familie Pobaschnig beweisen konnte.</p>
<p>Bernd weiß zu sagen: <i>"Gott sei Dank weiß man nicht alles, was auf einen zukommt. Wenn man das alles wissen würde, weiß ich nicht ganz genau, ob man sowas angeht."</i></p>

<h2>Egal ob über Selbstbedienungshütten oder Gastronomie - Das Eis geht weg wie warme Semmeln. Und mittlerweile wurde der Betrieb mehrmals ausgezeichnet.</h2>
<p>Die frische Milch ihrer 50 Kühe und 50 Ziegen bieten die Basis ihrer Erzeugung und werden direkt auf dem Hof verarbeitet. Ein Großteil geht für die normale Milchproduktion aus dem Haus, eine gewisse Menge wird für die Manufaktur vor Ort genutzt. <br />Auch der Vertrieb der kühlen Süßigkeit bleibt regional - zwei eigene Selbstbedienungshütten bieten das Sortiment an, drei weitere werden regelmäßig beliefert. Auch in einem Umkreis von ungefähr 50 km wird das Eis in Gastbetrieben als leckere Besonderheit verkauft. Lorbeeren wurden auch schon eingesammelt, beispielsweise 2014 mit der Auszeichnung Bio Betrieb des Jahres oder 2016 mit der Auszeichnung Weiterentwicklung im ländlichen Raum durch Bundesminister Andrä Rupprechter und BioAustria Obfrau Gerti Grabmann in Salzburg. 2019 gelang ihnen mit dem schmackhaften Buttermilcheis mit Preiselbeeren der Preis des Goldenen Stanitzels in der Kategorie Eisspezialitäten.</p>

<h2>Mit Erfindergeist und Weiterentwicklung wird die Zukunft angetreten.</h2>
<p>Wenn der Bio-Betrieb beim gemeinsamen Familienfrühstück die Aufteilung der anstehenden Arbeiten am Hof, an der Instandhaltung der eigenen Arbeitsmaschinen oder an den neuen Ideen für weitere cremige Köstlichkeiten bespricht, scheint die Morgensonne mit einem durchaus positiven Blick durchs Fenster. Das Ziel von Bernd und Petra ist, den Vollerwerbsbetrieb nun aufrecht zu erhalten und in der Familie zu behalten. Mit dem inzwischen guten Zuspruch ihrer Arbeitskollegen und benachbarten Freunden scheint aber auch die nötige Unterstützung gesichert zu sein und so kann man sich auch weiterhin auf leckere Kreationen der Eismanufaktur auf dem Krappfelder Bio-Bauernhof freuen.</p>
</>

const krappfelder = {
  path,
  img,
  photos,
  videos,
  title,
  short_title,
  intro,
  content
}

export default krappfelder;
