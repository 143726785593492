import biomassehof from './biomassehof';
import zacherlgut from './zacherlgut';
import feldalm from './feldalm';
import pilzzucht from './pilzzucht';
import seppbauer from './seppbauer';
import theurerhof from './theurerhof';
import krappfelder from './krappfelder';
import gemuesefreude from './gemuesefreude';
import weingut from './weingut';


const hof = [feldalm, theurerhof, krappfelder, zacherlgut, pilzzucht, seppbauer, biomassehof, gemuesefreude, weingut];

export default hof;
