import photo1 from './Biomassehof/1.jpg';
import photo2 from './Biomassehof/2.jpg';
import photo3 from './Biomassehof/3.jpg';
import photo4 from './Biomassehof/4.jpg';
import photo5 from './Biomassehof/5.jpg';

const title = 'Energie aus Biomasse – Energiewerk Ilg aus Dornbirn';
const short_title = 'Biomassehof';
const path = 'Biomassehof';
const videos = {
  lang: 'https://www.youtube.com/watch?v=DK1vXvvH6RM',
  kurz: 'https://www.youtube.com/watch?v=cee9uNPP-q4'
}
const img = photo1;
const photos = [
  {src: photo1, width: 1, height: .6675},
  {src: photo5, width: 1, height: .55},
  {src: photo2, width: 1, height: .6675},
  {src: photo3, width: 1, height: .6675},
  {src: photo4, width: 1, height: .6675}
];

const intro = `Die Liebe zum Wald wurde der Familie Ilg durch den Großvater in die Wiege gelegt. Heute spezialisiert sie sich auf die nachhaltige Energiegewinnung aus Holz und weiteren nachwachsenden Rohstoffen und leistet damit Pionierarbeit.`; 
const content = <>
<h2>Von der Milchkuhhaltung zum Energiewerk</h2>
<p>Das Ehepaar Margit und Tobias Ilg aus Dornbirn haben ihren landwirtschaftlichen Betrieb im Jahr 2000 von der Milchkuhhaltung auf die Erzeugung erneuerbarer Energie umgestellt. Änderungen im Tierschutzgesetz und der Wille, mehr zum Klimaschutz beizutragen, gaben damals den Anstoß über alternative Betriebszweige nachzudenken. Mutig fassten sie den Entschluss, ihren Familienbetrieb neu auszurichten und investierten in innovative Verfahren der Energiegewinnung. Seitdem arbeiten sie daran, die Energiewende aktiv mitzugestalten. Sie generieren erfolgreich Energie aus Biogas, Biomasse, Photovoltaik und Windkraft. 
„Es war ein schwieriger Schritt, Altbekanntes loszulassen,“ erinnert sich der Energielandwirt Tobias Ilg über die Anfänge der Umstrukturierung. Bereut haben sie ihre Entscheidung bis heute nicht und sind glücklich darüber, dass die ganze Familie mit Leib und Seele an gemeinsamen Projekten mitarbeitet. </p>

<h2>Den Energiekreislauf leben</h2>
<p>Das Energiewerk Ilg besteht heute aus sechs Biomasseheizanlagen, einer Holzgasanlage, drei Photovoltaikanlagen und einem Windrad. Auch das Thema E-Mobilität wird am Hof ganz groß geschrieben. Alle betrieblichen Fahrzeuge werden mit Biogasmotoren betrieben. Für die Mitarbeiter stehen E-Bikes zur Verfügung. 
Ziel ist es, langfristig eine kostengünstige, regionale und nachhaltige Alternative zu fossilen Energieträgern zu bieten und Rohstoffkreisläufe zu optimieren. 200.000 kWh aus der Photovoltaik, 1,6 Mio. kWh aus Biogas und 2 Mio. kWh durch die Verstromung von Holzgas können derzeit produziert werden.
Damit wird bereits jede zehnte Person in Dornbirn mit Strom und 270 Gebäude mit Strom und Wärme aus dem Werk versorgt (Stand: 2018). 
Zu den hochtechnologischen Maschinen gehört unter anderem eine Biogasanlage zur Stromgewinnung, die ausschließlich mit land- und forstwirtschaftlichen Produkten wie Mais, Grünland oder Streuobst aus eigener Produktion betrieben wird. In der Biogasanlage werden die Rohstoffe mithilfe von Methanbakterien zu Biomethan abgebaut und im Blockheizkraftwerk zu Strom umgewandelt. Mit der Abwärme der Motoren werden jährlich außerdem 15.000 Schüttraummeter Hackgut getrocknet.</p>

<h2>Ausbildung von Nachwuchskräften</h2>
<p>Die innovativen Verfahren der Familie Ilg locken Besucher aus aller Welt nach Dornbirn. Über 1.000 Personen werden pro Jahr am Hof empfangen. Besonders willkommen sind Schulklassen, die Tobias Ilg mit seinem Wissen über Nachhaltigkeit und erneuerbare Energie begeistert.
Familie Ilg bildet als erster Betrieb in Vorarlberg Biomassefacharbeiter aus. Als Innovationsträger realisiert die Familie Ilg außerdem auch Projekte mit Hochschulen in und außerhalb von Vorarlberg.</p>

<h2>Innovation in der Landwirtschaft </h2>
<p>Tobias Ilg ist sich sicher – „Dinge, die Sinn ergeben, werden eines Tages auch wirtschaftlich“. Mit gutem Beispiel möchten er und seine Familie auch andere landwirtschaftliche Betriebe dazu ermutigen neue Wege zu beschreiten.</p>
</>

const biomassehof = {
  path,
  img,
  photos,
  videos,
  title,
  short_title,
  intro,
  content
}

export default biomassehof;
