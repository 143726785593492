import React from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Error404 from 'routes/Error404';
import routes from 'constants/routes';


export default function App() {
	return (
		<>
			<Router>
				<Switch>
					{routes.map(route => {
						let Component = route.component;
						return <Route key={'route-'+route.id} exact path={route.path}><Component /></Route>
					})}
					<Route component={Error404} />
				</Switch>
			</Router>
		</>
	)
}
