import photo1 from './Theurerhof/1.jpg';
import photo2 from './Theurerhof/2.jpg';
import photo3 from './Theurerhof/3.jpg';
import photo4 from './Theurerhof/4.jpg';
import photo5 from './Theurerhof/5.jpg';

const title = 'Genusshof Theurer: Haselnüsse im Waldviertel';
const short_title = 'Genusshof Theurer';
const path = 'Genusshof-Theurer';
const img = photo2;
const videos = {
  lang: 'https://www.youtube.com/watch?v=qAFisygRWho',
  kurz: 'https://www.youtube.com/watch?v=FJLGbCXjaZ4'
}
const photos = [{src: photo1, width: 1, height: .5625},{src: photo2, width: 1, height: 0.5625},{src: photo3, width: 1, height: .5625},{src: photo4, width: 1, height: .5625},{src: photo5, width: 1, height: 0.5625}];
const intro = `Familie Theurer hat eine spannende Kultur für sich und das Waldviertel entdeckt: die Haselnuss. Mit dieser Kultur leisten sie vom Anbau bis zur Ernte wegbereitende Arbeit. Am GeNusshof Theurer können sich Besucher selbst ein Bild davon machen.`; 
const content = <>
<p>Auf die ungewöhnliche Idee, Haselnüsse anzubauen, brachte sie Dr. Rolf Rapp in Bayern. Der Haselnuss-Pionier aus Deutschland besuchte vor 10 Jahren das Waldviertel und prognostizierte eine gute Eignung des Gebiets für den Anbau von Haselnüssen. Matthias Theurers Vater war überzeugt und wagte den Schritt auf 3,6 Hektar Ackerfläche 2.000 Pflanzen zu setzen. „Du musst schon ein Spinner sein, wenn du etwas machst, das es in Österreich so noch gar nicht gibt. Und das aus voller Überzeugung“, lacht Matthias Theurer im Nachhinein über die mutige Entscheidung seines Vaters. Benachbarte Landwirte haben sein Vorhaben mit Skepsis beobachtet.</p>
<h2>Haselnuss, Ginkgo und Walnüsse aus dem Waldviertel </h2>
<p>Matthias Theurer hat den Hof vor drei Jahren von seinen Eltern übernommen. Zum Familienbetrieb zählt eine Nutzfläche von 36 Hektar. In der Vergangenheit war es ein Milchviehbetrieb. Heute werden hauptsächlich Haselnüsse, Ginkgo und demnächst auch Walnüsse angebaut. Gemeinsam mit fünf weiteren Betrieben produzieren sie innerhalb eines Vereins, Matthias selbst fungiert als Obmann. Die Mitglieder teilen sich Maschinen, verarbeiten und vermarkten ihre Ware jedoch auf unterschiedliche Weise. Mattias Theurer gilt in der Szene als Vorreiter und unterstützt andere Produzenten gerne als Berater. Er selbst vermarktet rund 60 % seiner Ware über Online-Kanäle. Dabei werden Produkte wie Haselnuss (geknackt), Präsentkörbe, Schnaps und Mehl angeboten.</p>

<h2>Moderne Anlagen erleichtern die Arbeit </h2>
<p>Rainer Lindner arbeitet als Hausmechaniker am Genusshof Theurer. Er kümmert sich um alle Maschinen, die für die Ernte und Verarbeitung der Haselnüsse notwendig sind. Im Laufe der Jahre hat er selbst schon einige Anlagen geplant und entwickelt. Gemeinsam mit Matthias Theurer tüfteln sie gerne an den hauseigenen Geräten. </p>
<p>Zu Beginn hat die Familie alles manuell geknackt, sortiert und verarbeitet. Durch die steigende Menge ist es unmöglich geworden, alles per Hand zu erledigen. Daher wurden eine Knack- und eine Sortiermaschine angeschafft.</p>

<h2>GeNuss erleben bei Familie Theurer</h2>
<p>Hauptanbaugebiete der Haselnuss sind die Türkei und Italien. Haselnüsse auch im Waldviertel im großen Stil anzubauen, ist ungewöhnlich. Daher weckte der Hof von Anfang an großes Interesse in der Bevölkerung. Aufgrund zahlreicher Besucheranfragen hat die Familie begonnen, Führungen inklusive einer Verkostung im Verarbeitungsraum abzuhalten. Das Angebot wird von den Gästen sehr gut angenommen. Kürzlich hat auch ein Tag der offenen Tür stattgefunden. Matthias Theurer freut sich über die erstaunten Gesichter jener Leute, die vor 10 Jahren noch an ihrem Vorhaben gezweifelt haben.<br />Sein großes Ziel ist es, den „GeNuss wieder ins Waldviertel zu holen“.</p>

</>

const theuerhof = {
  path,
  img,
  photos,
  videos,
  title,
  short_title,
  intro,
  content
}

export default theuerhof;
