import React, {useState, useRef, useCallback, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import {createUseStyles} from 'react-jss';
import ReactPlayer from 'react-player';
import Footer from 'components/Footer';
import Gallery from 'react-photo-gallery';
import { a, useSpring } from 'react-spring';

import styles from './Home.style';
import hof from 'lib/hof';

import playIcon from 'static/play-circle.svg';
import infoIcon from 'static/information.svg';
import logo from 'static/logo.svg';
import homeIcon from 'static/home-group.svg';
import bg1 from 'static/bg1.svg';
import bg2 from 'static/bg2.svg';
import bg3 from 'static/bg3.svg';
import bg4 from 'static/bg4.svg';
import bg5 from 'static/bg5.svg';

const useStyles = createUseStyles(styles);

const Hof = () => {
  const { id, video } = useParams();
  const [hofList, setHofList] = useState();
  const [longVideo, setLongVideo] = useState(true);
  const [currentHof, setCurrentHof] = useState(false);
  const classes = useStyles();
  const initSpringPos = [0,0,0];
  const contentRef = useRef();

  const [videoWrapper, videoWrapperRef] = useSpring(() => ({ 
    paddingTop: "0%",
    config: { duration: 800 }
  }));
  const [springWrapper, springWrapperRef] = useSpring(() => ({ 
    paddingTop: "25%", 
    config: { duration: 800 }
  }));
  const [springProps1, springRef1] = useSpring(() => ({ 
    top: initSpringPos[0] + "%", 
    config: { duration: 800 }
  }));
  const [springProps2, springRef2] = useSpring(() => ({ 
    top: initSpringPos[1] + "%", 
    config: { duration: 800 }
  }));
  const [springProps3, springRef3] = useSpring(() => ({ 
    top: initSpringPos[2] +"%", 
    config: { duration: 800 }
  }));

  const animate0 = useCallback(() => {
    springWrapperRef.update({paddingTop: '18%'});
    springWrapperRef.start();
    springRef1.update({top: 0 + '%'});
    springRef1.start();
    springRef2.update({top: 0 + '%'});
    springRef2.start();
    springRef3.update({top: 0 + '%'});
    springRef3.start();
  },[springRef1, springRef2, springRef3, springWrapperRef]);

  const animate1 = useCallback(() => {
    springWrapperRef.update({paddingTop: '65%'});
    springWrapperRef.start();
    springRef1.update({top: 10 + '%'});
    springRef1.start();
    springRef2.update({top: 30 + '%'});
    springRef2.start();
    springRef3.update({top: 50 + '%'});
    springRef3.start();
  },[springRef1, springRef2, springRef3, springWrapperRef]);

  const vidAnimate2 = useCallback(() => {
    videoWrapperRef.update({paddingTop: '0%'});
    videoWrapperRef.start();
  },[videoWrapperRef]);

  useEffect(() => {
    const shuffle = (array) => {
      var currentIndex = array.length, temporaryValue, randomIndex;
      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      return array;
    }
    setHofList(shuffle(hof));
  },[]);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, [video, id]);

  useEffect(() => {
    if(id){
      setCurrentHof(hof[hof.findIndex(h => h.path === id)]);
      animate1();
      vidAnimate2();
    } else {
      animate0();
      vidAnimate2();
      setCurrentHof();
    }
  },[animate0, animate1, id, vidAnimate2]);


  return <div className={classes.hof} style={video ? {height: '100vh', overflow: 'hidden'} : {}}>
    <div className={classes.logoWrapper}><Link className={classes.logo} to="/"><img src={logo} alt=""/></Link></div>
    <div style={{height: '80px', width: '100%', maxWidth: '980px', margin: '0 auto'}}>
      {currentHof && <div className={classes.breadcrumbs} style={{opacity:0}}>
        <div style={{backgroundColor: '#eee', borderRadius: '4px'}}>
          <Link to="/"><img src={homeIcon} style={{width: '40px', height: '40px'}} alt="Höfe" /></Link>
          <b>{currentHof.short_title}</b>
        </div>
        <div>
          {currentHof && <span className={classes.action} onClick={() => setLongVideo(!longVideo)}>
            <img src={playIcon} alt="" />
            {longVideo ? 'Kurzversion' : 'Langversion'}
          </span>}
          {currentHof && <span style={{display: 'flex', flexDirection: 'column', cursor: 'pointer', marginLeft: '16px'}} onClick={() => contentRef.current.scrollIntoView()}>
            <img src={infoIcon} alt="" />
            Information
          </span>}
        </div>
      </div>}
    </div>
    <header className={classes.header}>
      <a.div className={classes.background} style={{...springWrapper}}>
        <a.div className={classes.landscape} style={{...springProps1, backgroundImage: 'url('+bg1+')'}}></a.div>
        <a.div className={classes.landscape} style={{...springProps2, backgroundImage: 'url('+bg2+')'}}></a.div>
        <a.div className={classes.landscape} style={{...springProps3, backgroundImage: 'url('+bg3+')'}}></a.div>
      </a.div>
      {currentHof && <div className={classes.videoScreen}>
            <div className={classes.videoWrapper} style={{...videoWrapper}}>
              <ReactPlayer
                className={classes.videoPlayer}
                width='100%'
                playing={true}
                controls={true}
                height='100%'
                url={longVideo ? currentHof.videos.lang : currentHof.videos.kurz} />
            </div>
          </div>}
    </header>
    <main className={classes.main} style={{backgroundImage: 'url('+bg5+')'}}>
      <img src={bg4} alt="" className={classes.landscapePath} />
      {hofList && <div className={classes.inner}>
          {!id ? hof.map((h, idx) => {
            let cardClass = idx%2 === 0 ? classes.card1 : classes.card2;
            let color2 = idx%2 === 0 ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,1)';
            let color = idx%2 === 0 ? 'rgba(105,151,97,1)' : 'rgba(132,191, 122,1)';
            let align = idx%2 === 0 ? 'right' : 'left';
            return <Link key={'litm-'+idx} to={h.path} className={classes.lnk} style={{animationDelay: .3*(idx+1) + 's'}}>
                <div className={cardClass} style={{backgroundColor: color}}>
                  <div className={classes.cardHeader} style={{backgroundImage: 'url('+h.img+')'}}><div /><img src={playIcon} className={classes.playItem} alt="Video abspielen" /></div>
                  <div className={classes.cardContent} style={{color: color2, textAlign: align}}>
                    <h2>{h.short_title}</h2>
                    <p className={classes.cardContentText}>{h.intro}</p>
                    <div />
                  </div>
                </div>
              </Link>}) : currentHof && <>
              {!video && <div className={classes.content} style={{opacity: 1}}>
                <h1 ref={contentRef}>{currentHof.title}</h1>
                {currentHof.content}
                <div style={{display: 'flex', justifyContent: 'center'}}><a href="https://meinhof-meinweg.at/" rel="noopener noreferrer" className={classes.back}>Mein Hof - Mein Weg</a></div>
                {currentHof.photos && <div><Gallery photos={currentHof.photos} direction={"column"} /></div>}
                <div style={{display: 'flex', justifyContent: 'center'}}><a href="https://meinhof-meinweg.at/" rel="noopener noreferrer" className={classes.back}>Mein Hof - Mein Weg</a></div>
              </div>}
              
            </>}
      </div>}
      {!video && <Footer />}
    </main>
  </div>

};
      
export default Hof;
