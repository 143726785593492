const styles = {
  btn: {
    textDecoration: 'none',
    backgroundColor: '#00d8ef',
    color: '#fff',
    padding: '8px 20px',
    height: '30px',
    lineHeight: '30px',
    borderRadius: '30px',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#44555f',
    }
  }
}

export default styles;
