import photo1 from './Pilzzucht/1.jpg';
import photo2 from './Pilzzucht/2.jpg';
import photo3 from './Pilzzucht/3.jpg';
import photo4 from './Pilzzucht/4.jpg';
import photo5 from './Pilzzucht/5.jpg';

const title = 'Hut und Stiel: Austernpilze gewachsen auf Kaffeesatz';
const short_title = 'Pilzzucht in Wien';
const path = 'Pilzzucht';
const img = photo4;
const photos = [{src: photo1, width: 1, height: .5652},{src: photo2, width: 1, height: 1},{src: photo3, width: 1, height: .6238},{src: photo4, width: 1, height: .6238},{src: photo5, width: 1, height: 0.5652}];
const videos = {
  lang: 'https://www.youtube.com/watch?v=Bpc_V5vOly8',
  kurz: 'https://www.youtube.com/watch?v=LS9mJIz356o'
}

const intro = `„Hut und Stiel“ aus Wien züchten Pilze auf dem Kaffeesatz Wiener Kaffeehäuser. Die Produktion der Austernpilze ist nachhaltig und die Qualität aufgrund der Nähe zu zahlreichen Restaurants erlaubt besondere Frische.`; 

export const content = <>
<p><i>Manuel Bornbaum und Florian Hofer züchten seit 2015 Austernpilze auf einem Abfallprodukt – dem Wiener Kaffeesatz. Sie wollen, dass Pilze aus nachhaltiger Produktion vermehrt auf den Tellern der Österreicher und Österreicherinnen landen. Mittlerweile beliefert „Hut und Stiel“ Restaurants, Greislereien und Supermärkte. Zudem bieten sie Do It Yourself Workshops an und kreieren neue Rezepte.</i></p>
<p>Wenn eine Stadt in Österreich ausreichend Kaffeesatz produziert, dann ist es Wien. Das haben sich auch die beiden Freunde Manuel Bornbaum und Florian Hofer gedacht. Im Jahr 2015 begannen sie den Kaffeesatz zahlreicher Kaffeehäuser, Restaurants und Großküchen einzusammeln und eröffneten ihre Pilzzucht „Hut und Stiel“. Denn auf Rohfasermaterial, wie es der Kaffeesatz ist, lassen sich wunderbar Speisepilze züchten.<br />
„Wir würden euch den Behälter zur Verfügung stellen und ihr kippt den Kaffeesatz einfach rein“, berichtet Manuel Bornbaum über den Beginn. Die Rückmeldungen waren stets positiv. Der Irrsinn, organisches Material im Restmüll anstelle im Biomüll zu entsorgen, fand damit ein Ende. Fortan holten die beiden Freunde zweimal in der Woche ihre Kaffeesatzbehälter ab. Heute beliefern sie gleichermaßen einige „Müllkunden“/“Müllwohltätern“ mit ihren Speisepilzen.</p>

<h2>Pilzsubstrat, der Nährboden für Austernpilze</h2>
<p>Doch um aus 1500 kg Kaffeesatz einen Nährboden zu machen und diesen in lebende Pilze zu verwandeln, braucht es Logistik. Am Standort im 22. Wiener Gemeindebezirk werden in großen Mischmaschinen Kaffeesatz, Schalenabfälle vom Röstprozess der Kaffeebohnen, Kalk, Strohpellets und Pilzmycel vermischt. Allein der Kaffeesatz macht einen Anteil von 85 Prozent des Pilzsubstrats aus. Sobald die Mischung fertig und verschlossen ist, beginnt das Wachstum. Binnen drei Wochen breitet sich der Pilz im gesamten Substrat aus. Sobald er keine Nahrung mehr findet, wächst er aus dem Gefäß heraus. Dann heißt es für die Geschäftsleute schnell zu handeln. Damit sie eine gleichbleibende Qualität an Austernpilzen liefern können, dürfen die Pilze weder zu groß noch zu klein sein. Das entspricht einem Erntefenster von zwei Tagen. Geerntet wird daher vier- bis fünfmal die Woche. Die Überreste der Pilzzucht werden später als Dünger für andere Pflanzen verwendet.</p>

<h2>Von der Freundlwirtschaft zum wachsenden Unternehmen</h2>
<p>Mit der wachsenden Produktion wurden auch Mitarbeiter benötigt. Spätestens damit ging ein durchdachter Businessplan und eine genaue Kalkulation einher. Auch in Sachen Recht wollten sich die beiden Betriebsgründer unterstützen. „Wenn dir die Arbeit vier Meter über dem Kopf steht, bist du froh, wenn diese Phase durch die Hilfe anderer ausgeglichen wird“, weiß Manuel Bornbaum. Aber die beiden waren sich von Anfang an sicher, dass sie wachsen und ihren Anteil an der Nachhaltigkeit leisten wollten. Aufgrund von Anfragen haben sie dann die reine Pilzproduktion, um Workshops und Starterpakete für die Pilzzucht Daheim erweitert. „Unsere Austernpilze schmecken nicht nach Kaffee. Koffein ist auch nicht drin. Was sie ausmacht ist die Frische durch die kurzen Transportwege“, sagt Florian Hofer. Auch in Sachen Betriebsführungen geben sie ihr Wissen weiter. Alle Informationen zur Pilzzucht sind daher öffentlich einsehbar und wandelbar. Auch Praktikanten sind jederzeit willkommen.</p>

<h2>Nicht nur der Pilz auch die Nachfrage wächst</h2>
<p>Ob als frische Pilze zur direkten Verarbeitung, im Glas oder zum Heranwachsen – die Produkte von „Hut und Stiel“ finden ihre Abnehmer. Restaurants und Supermärkte beziehen die Austernpilze regelmäßig und haben „Hut und Stiel“ in ihren Speisekarten fest integriert. Auch im Wiener Gemüsehandel sind die Speisepilze nicht mehr zu übersehen. Zwei der neusten Ideen sind das Pilzsandwich und das Pilzwürstel. Denn ein Würstelstand in Wien lässt sich ebenso wenig Wegdenken wie ein traditionelles Kaffeehaus.</p>
</>

const pilzzucht = {
  path,
  img,
  photos,
  videos,
  title,
  short_title,
  intro,
  content
}

export default pilzzucht;
